<template>
  <codemirror
    v-model="code"
    ref="cmEditor"
    @input="handleChange"
    :options="cmOptions"
  />
</template>

<script>
import { codemirror } from "vue-codemirror";
// import language js
import "codemirror/mode/javascript/javascript.js";
import "codemirror/mode/css/css.js";
import "codemirror/mode/xml/xml.js";

// import theme style
import "codemirror/theme/base16-dark.css";

// import more 'codemirror/some-resource...'
import "codemirror/lib/codemirror.css";
import "codemirror/addon/hint/show-hint.js";
import "codemirror/addon/hint/javascript-hint";
import "codemirror/addon/hint/html-hint";
import "codemirror/addon/hint/css-hint";
import "codemirror/addon/hint/show-hint.css";

export default {
  props: ["data"],
  components: {
    codemirror,
  },
  data() {
    return {
      code: this.data.code,
      cmOptions: {
        tabSize: 4,
        mode: this.data.type,
        theme: "base16-dark",
        lineNumbers: true,
        line: true,
        matchBrackets: true,
        autoCloseBrackets: true,
        smartIndent: true,
        autoCloseTags: true,
        extraKeys: {"Tab": "autocomplete"},
        hintOptions:{
          completeSingle: false
        },
        lineWrapping: true,
        // more CodeMirror options...
      },
    };
  },
  computed: {
    codemirror() {
      return this.$refs.cmEditor.codemirror;
    }
  },
  methods: {
    handleChange() {
      this.$emit("onChange", this.code);
    }
  },
};
</script>
<style>
.CodeMirror {
  max-height: 70vh;
  min-height: 60vh;
}
</style>