<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="6">
        <v-expansion-panels v-model="expanded" multiple>
          <v-expansion-panel>
            <v-expansion-panel-header>HTML</v-expansion-panel-header>
            <v-expansion-panel-content>
              <Editor :data="html" @onChange="onHTMLChange" />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header> CSS</v-expansion-panel-header>
            <v-expansion-panel-content>
              <Editor :data="css" v-on:onChange="onCSSChange" />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <!-- <v-expansion-panel>
            <v-expansion-panel-header> JavaScript</v-expansion-panel-header>
            <v-expansion-panel-content>
              <Editor :data="js" v-on:onChange="onJSChange" />
            </v-expansion-panel-content>
          </v-expansion-panel> -->
        </v-expansion-panels>
      </v-col>
      <v-col cols="12" md="6">
        <v-card class="pa-0" height="100%">
          <v-card-title>
            <v-btn color="primary" small outlined @click="runScript"
              ><v-icon small class="mr-1"
                >mdi-checkbox-multiple-marked-outline</v-icon
              >Run</v-btn
            >
          </v-card-title>
          <v-card-text style="height: 88%">
            <iframe
              style="background: white"
              :srcdoc="srcDoc"
              title="output"
              sandbox="allow-scripts"
              width="100%"
              height="100%"
              frameBorder="0"
            ></iframe>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Editor from "./Editor.vue";

export default {
  components: {
    Editor,
  },
  data() {
    return {
      srcDoc: "",
      expanded: [0, 1, 2],
      html: {
        type: "xml",
        code: "<h1>Hello World !</h1>",
      },
      css: {
        type: "css",
        code: "body{padding:50px; margin:0; color:red}",
      },
      js: {
        type: "javascript",
        code: "document.body.style.backgroundColor = 'green'",
      },
    };
  },
  created() {
    this.runScript();
  },
  mounted() {
    this.expanded = [0];
  },
  methods: {
    onHTMLChange(code) {
      this.html.code = code;
    },
    onCSSChange(code) {
      this.css.code = code;
    },
    onJSChange(code) {
      this.js.code = code;
    },
    runScript() {
      this.srcDoc =
        "<html><body>" +
        this.html.code +
        "</body><style>" +
        this.css.code +
        "</style><script>" +
        this.js.code +
        "</scr " +
        "ipt>" +
        '</html>"';
    },
  },
};
</script>
